body {
    background-color: #F9F9F9;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@font-face {
    font-family: 'Montserrat';
    src: local("Montserrat-Regular"), url("../fonts/Montserrat-Regular.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: 'Montserrat';
    font-weight: bold;
    src: local("Montserrat-Bold"), url("../fonts/Montserrat-Bold.ttf") format("truetype");
}
  
@font-face {
    font-family: 'Montserrat';
    src: local("Montserrat-Medium"), url("../fonts/Montserrat-Medium.ttf") format("truetype");
    font-weight: 500;
}

.survey {
    background-color: #F9F9F9;
    font-family: 'Montserrat';
    padding: 50px 0;
}

.survey-fill--custom.survey-fill {
    color: #2B2B2B;
}

.survey-fill .question .question__title {
    font-size: 18px;
    display: flex;
    justify-content: space-between;
}

.survey-fill .question .question__title .question__title-wrapper {
    display: flex;
}

.survey-fill .question .question__name {
    max-width: 620px;
}

.question-html-title {
    max-width: 700px;
    font-size: 18px;
    font-weight: 500;
}

.survey-fill--custom.survey-fill .question .question__required {
    color: #FF3C3B;
}

.survey-fill .question .question__wrapper {
    margin-top: 15px;
    margin-bottom: 10px;
}

.survey-fill--custom.survey-fill input.form-control {
    border-color: #E5E5E5;
}

.survey-fill--custom.survey-fill input.form-control {
    background-color: #FFFFFF;
}

.survey-fill input.form-control {
    height: 46px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    border: 1px solid transparent;
}

.survey-fill .question + .question {
    margin-top: 50px;
}

.survey-fill .question .question__other {
    margin-top: 10px;
}

.collapse.in {
    display: block;
}

.survey-fill .question .question__hint {
    font-size: 80%;
    margin-top: 10px;
}

.survey-fill--custom.survey-fill .question .question__panel {
    border-color: #E5E5E5;
    background-color: #FFFFFF;
}

.survey-fill .question .question__panel {
    border: 1px solid transparent;
}

.survey-fill .question .question-list {
    font-size: 13px;
}

.survey-fill .question.question--select .question-list .question-list__item {
    padding-left: 40px;
}

.survey-fill .question .question-list label.question-list__item {
    cursor: pointer;
}

.survey-fill .question .question-list .question-list__item {
    width: 100%;
    height: 100%;
    font-weight: 500;
    padding: 15px 20px;
    margin: 0;
    border: 1px solid transparent;
    position: relative;
}

.survey-fill .question.question--select .question-list .question-list__item input[type="radio"] {
    position: absolute;
    left: 14px;
    top: 19px;
    margin: 0;
}

.survey-fill .question .question-list input {
    font-size: 13px;
}

.survey-fill .question .question-list .question-list__item-text {
    margin-left: 10px;
}

.survey-fill--custom.survey-fill .question .question-list .question-list__item.active {
    background-color: #787878;
    border-color: #787878;
    color: #FFFFFF;
}

.survey-fill .question.question--select .question-list .question-list__item {
    padding-left: 40px;
}

.survey-fill .question .question-list .question-list__item.active {
    border: 1px solid transparent;
}

.survey-fill--custom.survey-fill .question .question-list .question-list__item:hover {
    border-color: #787878;
}

.survey-fill .question .question-list .question-list__item:hover {
    border: 1px solid transparent;
}

.survey-fill .question .question-list .question-list__item-text + textarea {
    margin-top: 20px;
}
.survey-fill .question .question-list textarea {
    font-size: 13px;
}

.survey-fill .question .question__hint {
    font-size: 80%;
    margin-top: 10px;
}

.survey-fill .question .question__comment {
    margin-top: 15px;
}

.survey-html-comment {
    max-width: 700px;
}

.survey-fill--custom.survey-fill .button.button--filled {
    border-color: #787878;
    background-color: #787878;
    color: #FFFFFF;
    transition: all 0.25s;
}

.survey-fill .button {
    cursor: pointer;
    font-weight: 500;
}

@media (max-width: 767px) {
    .survey-fill .button {
        padding: 4px 6px;
        font-size: 16px;
    }
}

.survey-fill .button {
    padding: 10px 25px;
    text-transform: uppercase;
    font-size: 16px;
    border: 2px solid transparent;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
}

.survey-fill .button.button--filled:active,
.survey-fill .button.button--filled:focus,
.survey-fill .button.button--filled:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.survey-fill .survey .survey__buttons {
    margin-top: 30px;
}

.react-select__control {
    width: 100%;
    border-radius: 2px !important;
    padding: 6px 4px 6px 3px;
    position: relative;
    cursor: pointer !important;
    border: 0.5px solid transparent;
    border-color: #E5E5E5 !important;
}

.react-select__control--is-focused {
    border: 0.5px solid transparent !important;
    border-color: #000 !important;
    box-shadow: none !important;
}

.survey-fill .question .question__panel:hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
}

.survey-fill--custom.survey-fill .question.question--with-error .question__name {
    color: #FF3C3B;
}

.survey-fill--custom.survey-fill .question.question--with-error .form-control {
    border-color: #FF3C3B;
}

.survey-fill--custom.survey-fill .question.question--with-error .question__panel {
    border-color: #FF3C3B;
}

.survey-fill--custom.survey-fill .question.question--with-error .question__error-message {
    color: #FF3C3B;
}

.success-block {
    text-align: center;
}
